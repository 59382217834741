.snackbar {
	width: 100%;
	height: 5.5vh;
	color: black;
	background-color: #d5b15b;
	text-align: center;
	font-size: 1rem;
	padding: 8px 0 2px 0;
}

.text {
	margin-bottom: 20px;
}
