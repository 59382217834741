.container-why {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  text-align: left;
  vertical-align: middle;
}

.box-why {
  flex: 1; /* Each box will take up equal space */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  padding: 5px; /* Space inside the box */
}

/* Optional: Add some styling for clarity */
.box1-why { font-size: 1.1rem; font-weight: 700;}

.box2-why{
  display: flex;
  justify-content: center; /* Align buttons in the center horizontally */
  align-items: center;     /* Align buttons in the center vertically */
  gap: 10px;               /* Space between buttons */
  /* padding-top:20px; */
}

.box2-why button {
  padding: 8% 14%;      /* Padding inside buttons */
  font-size: 16px;         /* Button text size */
  cursor: pointer;         /* Cursor as pointer to indicate button */
  border: 1px solid #ccc;  /* Button border */
  background-color: #d5b15b; /* Button background color */
  transition: background-color 0.3s; /* Transition for hover effect */
  /* margin-top: -0.5%; */
  border-radius: 3px;
}
.box1-package{font-weight: 800;}
/* .box2-why { background-color: lightgreen; }  */

/* Ensure the boxes do not wrap on smaller screens */
@media (max-width: 600px) {
  .container-why {
    flex-direction: row;
  }
  .box-why {
    flex: 1; /* Keep this to ensure they still try to take equal space */
  }
}
